<template>
  <div>

  </div>
</template>


------------------ script ------------------
<script>
import {sleep} from "../../config/util";

export default {
  async mounted(){
    this.$toast.error('请在电脑打开');
    await sleep(1000)
    this.$router.replace('/');
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped></style>
